export const summaryDataSettelment = [
    {
      imgSrc: "DashboardDeposite",
      key: "total_commission",
      text: "Total Commission",
    },
    // {
    //   imgSrc: "DashboardWithdrawal",
    //   key: "total_payout_commission",
    //   text: "Total Withdrawal Commission",
    // },
    {
      imgSrc: "DashboardUnsettled",
      key: "settlement_amount",
      text: "Total Settled Amount",
    },
    {
      imgSrc: "DashboardActiveBalance",
      key: "pending_commission",
      text: "Total Unsettled Amount",
    },
  ];