import { Navigate, Route, Routes } from "react-router-dom";
import { Fragment } from "react";

// PROJECT IMPORT
import {
  Dashboard,
  Deposit,
  Header,
  Settlement,
  SideBar,
  Withdrawal,
} from "../../features";
import { useIsDrawerOpen } from "../../features/common/commonSlice";
import { useMedia } from "../wrappers";
import { useUser } from "../../features/auth/authSlice";
import { Manual } from "../../features/manual";

const Admin = () => {
  const { media } = useMedia();
  const user = useUser();

  const isDrawerOpen = useIsDrawerOpen();

  const Components: any = {
    Dashboard: Dashboard,
    Deposit: Deposit,
    Withdrawal: Withdrawal,
    Settlement: Settlement,
    Manual: Manual,
  };

  return (
    <div className=" h-screen sm:ml-2 sm:my-2 sm:h-[calc(100vh-0px)] overflow-y-scroll main relative">
      <SideBar />
      <div
        className={`${
          isDrawerOpen && media.width < 768
            ? "pl-[10px]"
            : isDrawerOpen && media.width > 768
            ? "pl-[240px]"
            : "pl-[100px]"
        } 
        ${media.width < 768 && !isDrawerOpen ? "pl-[10px]" : ""} 
        ${media.width < 768 ? "pr-[10px]" : "pr-[18px]"} 
         mainAdmin`}
      >
        <Header />
        <Routes>
          {user?.SiderBar?.map((item: any, index: number) => {
            const Element = Components?.[item?.name];
            if (!Element) {
              return <></>;
            }
            return (
              <Fragment key={index}>
                {index === 0 ? (
                  <Route path="/" element={<Navigate to={item?.route} />} />
                ) : null}
                <Route path={item?.route} element={<Element />} />
              </Fragment>
            );
          })}

          <Route
            path="*"
            element={<Navigate to={user?.SiderBar?.[0]?.route} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Admin;
