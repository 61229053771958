import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// UI IMPORT
import { Loader } from "./ui";

// PROJECT IMPORT
import AllRoutes from "./components/routes/AllRoutes";
import { useUser } from "./features/auth/authSlice";
import { setStyles } from "./helperFunctions";
import { useLogout } from "./hooks";
import "./App.css";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";

function App() {
  const user = useUser();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const color: any = process.env.REACT_APP_COLOR;

  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [user]);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-darkblue--", color);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useLogout();
  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }
  return (
    <div className="App">
      <AllRoutes />
      <Toaster />
    </div>
  );
}

export default App;
