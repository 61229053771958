import React, { CSSProperties } from "react";
import { BiMenuAltLeft } from "react-icons/bi";

interface TableHeaderProps {
  className?: string;
  style?: CSSProperties;
  isExpendable?: boolean;
  columns: any[];
}
const TableHeader = (props: TableHeaderProps) => {
  const {
    className = "",
    style = {},
    columns = [],
    isExpendable = true,
  } = props;
  return (
    <thead
      className={`${className} table-header sticky text-sm font-medium text-[#000] top-0 bg-white h-[50px]`}
      style={{ ...style }}
    >
      <tr>
        {isExpendable ? (
          <th className="no-wrap">
            <BiMenuAltLeft />
          </th>
        ) : null}
        {columns?.map((column, index) => {
          return (
            <th key={index} className="text-start no-wrap">
              {column?.title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
