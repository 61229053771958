export const staticSummaryData = [
  {
    img: "RechargeSuccess",
    key: "TOTAL_BALANCE",
    text: "Total Deposit",
  },
  {
    img: "RechargePending",
    key: "TOTAL_WITHDRAWAL",
    text: "Total Withdrawal",
  },
];

export const transactionSummaryData = [
  {
    img: "SucessIcon",
    key: "totalAmt",
    text: "Success",
  },
  {
    img: "PendingIcon",
    key: "totalAmt",
    text: "Pending",
  },
  {
    img: "IntializeIcon",
    key: "totalAmt",
    text: "Initialized",
  },
  {
    img: "ProcessingIcon",
    key: "totalAmt",
    text: "Processing",
  },
];

export const tableData: any[] = [
  {
    id: 1,
    bank_details: {
      account_holder_name: "John Doe",
      ifsc_code: "ABCD1234",
      upi_id: "john.doe@upi",
    },
    account_number: "1234567890",
    mmm: "kmk",
  },

  {
    id: 2,
    bank_details: {
      account_holder_name: "Jane Smith",
      ifsc_code: "EFGH5678",
      upi_id: "jane.smith@upi",
    },
    account_number: "9876543210",
  },
];
