// UI IMPORT
import { ToasterTypes } from "./ui";

// PROJECT IMPORT
import { clearAuth } from "./features/auth/authSlice";
import { clearCommon } from "./features/common/commonSlice";
import { clearDashboard } from "./features/dashboard/dashboardSlice";
import { clearDeposit } from "./features/deposit/depositSlice";
import { clearWithdrawal } from "./features/withdrawal/withdrawalSlice";
import { AppDispatch } from "./utills/store";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";

const root = window.document.documentElement;

export const kFormatter = (num: any) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch: AppDispatch) => {
  dispatch(clearAuth());
  dispatch(clearCommon());
  dispatch(clearDashboard());
  dispatch(clearDeposit());
  dispatch(clearWithdrawal());
};

export const downloadFile = (url: any, type: string, name: string) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (
  message: string,
  type: ToasterTypes = "Success"
) => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const changeFavicon = (newFaviconUrl: any) => {
  const favicon: any = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr: string, color: string) => {
  root?.style.setProperty(varr, color);
};

export const dateFormatter = (d: any, type = "start") => {
  if (!d) {
    return null;
  }
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}${
    type === "end"
      ? " 23:59:59"
      : type === "start"
      ? " 00:00:00"
      : type === "Default"
      ? ""
      : " " + hour + ":" + minute + ":" + seconds
  }`;
};

export const seperator = (x: any, rupee: any = true) => {
  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const val = f.format(Math.abs(+x) || 0)?.toString();

  const newValues = val?.split(".");
  let newVal = val;
  if (newValues?.length === 2 && newValues?.at(-1) === "00") {
    newVal = newValues?.[0];
  }
  if(!rupee){
    newVal = newVal?.slice(1);
  }

  return  x?.toString()?.slice(0,1) === '-' ? `-${newVal}` : newVal 
};

export const prepareMaxDate = (startDate: Date, endDate: Date, maxDay = 30) => {
  if (!startDate || (startDate && endDate)) {
    return new Date();
  }
  const d = new Date(startDate);
  d.setDate(d.getDate() + maxDay);
  return d > new Date() ? new Date() : d;
};
