import { useState } from "react";
import { useAddManualMutation } from "./manualApi";
import { useForm } from "react-hook-form";
import { Button, ControlledDatePicker, Input, Loader, Select } from "../../ui";
import { dateFormatter, showToaster } from "../../helperFunctions";
import { typeOptions } from "./mock";

const ManualModal = (props: any) => {
  const { isOpen, onClose, onGet } = props;

  const [addManual] = useAddManualMutation();
  const [isLoader, setIsLoader] = useState(false);

  const onSubmit = async (values: any) => {
    setIsLoader(true);
    const payload = {
      ...values,
      date: dateFormatter(values?.date, "Default"),
    };
    try {
      const res: any = await addManual(payload).unwrap();
      showToaster(res);
      setIsLoader(false);
      onGet();
      onHide();
    } catch (err: any) {
      setIsLoader(false);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      type: "All",
    },
  });

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left px-6">
          <form
            className="flex flex-wrap gap-2 items-start px-1"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              className="!w-full"
              name="type"
              label="Type"
              options={typeOptions}
              register={register}
            />
            {/* <Select
              className="!w-full"
              name="merchantId"
              label="Client"
              options={merchantOptions}
              register={register}
            /> */}
            <Input
              type="text"
              className="!w-full"
              name="merchantId"
              label="Client"
              placeholder="Merchant Id"
              register={register}
            />
            <ControlledDatePicker
              className="!w-full"
              inputClassName="!w-full"
              name="date"
              label="Date"
              placeholder="Date"
              maxDate={new Date()}
              control={control}
            />
            <Input
              type="number"
              className="!w-full"
              name="totalTxn"
              label="Total Txn"
              placeholder="Email Total Txn"
              register={register}
            />
            <Input
              type="number"
              className="!w-full"
              name="totalAmt"
              label="Total Amount"
              placeholder="Email Total Amount"
              register={register}
            />
            <Input
              type="number"
              className="!w-full"
              name="successAmt"
              label="Success Amount"
              placeholder="Email Success Amount"
              register={register}
            />
            <Input
              type="number"
              className="!w-full"
              name="commission"
              label="Commission"
              placeholder="Email Commission"
              register={register}
            />
            <Input
              type="number"
              className="!w-full"
              name="commisssionPer"
              label="Commission Per"
              placeholder="Email Commission Per"
              register={register}
            />
          </form>
          <div className="flex justify-end pt-2 items-center gap-3">
            <Button variant="Transaparent" onClick={onHide}>
              Close
            </Button>
            {!isLoader ? (
              <Button type="submit">Save</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ManualModal;
