import { api } from "../../utills/api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSummary: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/dashboard/summary",
        body: params,
        method: "POST",
      }),
    }),
    getMerchantList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/merchant/list",
        body: params,
        method: "POST",
      }),
    }),
    getDepositChart: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/chart/Deposit",
        body: params,
        method: "POST",
      }),
    }),
    getWithdrawalChart: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/chart/Withdrawal",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetSummaryMutation, useGetMerchantListMutation,useGetDepositChartMutation ,useGetWithdrawalChartMutation} =
  dashboardApi;
export const {
  endpoints: { getSummary, getMerchantList },
} = dashboardApi;
