import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { dateFormatter, seperator } from "../../helperFunctions";
import { useGetManualMutation } from "./manualApi";
import { useManualData } from "./manualSlice";
import { FILTER } from "../../constant";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import ManualModal from "./ManualModal";
import { summaryData, typeOptions } from "./mock";
import { Card } from "../common";

const Manual = () => {
  const data = useManualData();

  const [filter, setFilter] = useState(FILTER);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getManual, { isLoading }] = useGetManualMutation();

  const columns = [
    {
      title: "Merchant ID",
      name: "merchantId",
    },
    {
      title: "Type",
      name: "type",
    },
    {
      title: "Total Txn",
      name: "totalTxn",
      Cell: (item: any) => {
        return <span>{seperator(item?.totalTxn)}</span>;
      },
    },
    {
      title: "Total Amount",
      name: "totalAmt",
      Cell: (item: any) => {
        return <span>{seperator(item?.totalAmt)}</span>;
      },
    },
    {
      title: "Success Amount",
      name: "successAmt",
      Cell: (item: any) => {
        return <span>{seperator(item?.successAmt)}</span>;
      },
    },
    {
      title: "Commission",
      name: "commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.commission)}</span>;
      },
    },
    {
      title: "Commission Per",
      name: "commisssionPer",
      Cell: (item: any) => {
        return <span>{item?.commisssionPer?.toFixed(2)}%</span>;
      },
    },
    {
      title: "Date",
      name: "date",
      Cell: (item: any) => {
        return <span>{item?.date}</span>;
      },
    },
  ];

  const { register, handleSubmit, reset, getValues, control } = useForm<any>({
    defaultValues: {
      type: "All",
    },
  });

  const onGetData = async () => {
    const values = getValues();

    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          startDate: dateFormatter(values?.date?.[0] || new Date(), "start"),
          endDate: dateFormatter(values?.date?.[1] || new Date(), "end"),
          merchantId: values?.merchantId,
          type: values?.type,
        },
      };
    } else {
      payload = {
        ...filter,
      };
    }
    delete payload["isFilter"];
    getManual(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const prepareMerchantOptions = () => {
    const options = data?.merchantList
      ?.slice()
      ?.sort((a: any, b: any) =>
        a?.merchant_name?.localeCompare(b?.merchant_name)
      )
      ?.map((val: any) => ({
        value: val?.merchant_id,
        label: val?.merchant_details?.merchant_name,
      }));
    return options?.length
      ? [{ value: "ALL", label: "ALL" }, ...options]
      : [{ value: "ALL", label: "ALL" }];
  };
  return (
    <>
      <div className="mt-[16px]">
        <form
          className="flex flex-wrap gap-2 items-start justify-end px-1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            name="type"
            label="Type"
            options={typeOptions}
            register={register}
          />
          <Select
            name="merchantId"
            label="Client"
            options={prepareMerchantOptions()}
            register={register}
          />
          <ControlledDatePicker
            name="date"
            label="Date"
            placeholder="Date"
            selectsRange
            maxDate={new Date()}
            control={control}
          />

          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Apply
          </Button>
          <Button
            variant="Transaparent"
            className="mt-[22px]  !text-sm !h-9 "
            onClick={() => {
              reset();
              setFilter({ ...FILTER });
            }}
          >
            Clear
          </Button>
          <Button
            type="button"
            className="mt-[22px] !h-9 !text-sm"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Add
          </Button>
        </form>

        <div className="mt-2">
          <div className="grid grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryData?.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryData.length}
                    index={index}
                    value={data?.summary?.[0]?.[item.key]}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="App mt-3 tableborder">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={data?.data || []}
              isExpendable={false}
            >
              {data?.data?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>

      <ManualModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        merchantOptions={prepareMerchantOptions()}
        onGet={onGetData}
      />
    </>
  );
};

export default Manual;
