import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Export,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  dateFormatter,
  prepareMaxDate,
  seperator,
  showToaster,
} from "../../helperFunctions";
import { summaryData } from "./mock";
import { useGetWithdrawalMutation } from "./withdrawalApi";
import { useWithdrawalData } from "./withdrawalSlice";
import { FILTER } from "../../constant";
import { Card } from "../common";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";

const getDefaultDates = () => {
  const date = new Date();
  return {
    date: [new Date(date.setDate(date.getDate() - 7)), new Date()],
  };
};

const initialValues = {
  client: "ALL",
  ...getDefaultDates(),
};

const Withdrawal = () => {
  const data = useWithdrawalData();
  const [filter, setFilter] = useState(FILTER);
  const [getWithdrawal, { isLoading }] = useGetWithdrawalMutation();
  const [maxDate, setMaxDate] = useState<any>(new Date());
  const [lastFilter, setLastFilter] = useState(initialValues);

  const columns = [
    {
      title: "Date",
      name: "date",
    },
    {
      title: "Total Txn",
      name: "total_txn",
    },
    {
      title: "Total Payout Amount",
      name: "total_payout_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_payout_amount)}</span>;
      },
    },
    {
      title: "Success Payout Amount",
      name: "success_payout_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.success_payout_amount)}</span>;
      },
    },
    {
      title: "Commission",
      name: "commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.commission)}</span>;
      },
    },
  ];
  const withdrawalColumns = [
    {
      title: "Name",
      name: "merchant_name",
    },
    {
      title: "Total Txn",
      name: "total_txn",
    },
    {
      title: "Total Payout Amount",
      name: "total_payout_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_payout_amount)}</span>;
      },
    },
    {
      title: "Success Payout Amount",
      name: "success_payout_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.success_payout_amount)}</span>;
      },
    },
    {
      title: "Commission",
      name: "commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.commission)}</span>;
      },
    },
    {
      title: "Commission Per",
      name: "commission_per",
      Cell: (item: any) => {
        return <span>{item?.commission_per}%</span>;
      },
    },
  ];

  const { register, handleSubmit, reset, getValues, control, watch } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const isExportable = () => {
    const values = getValues();

    return (
      values?.date?.[0] &&
      values?.date?.[1] &&
      values?.client !== "ALL" &&
      values?.client &&
      values?.client === lastFilter?.client &&
      !isLoading
    );
  };

  const prepareCsvProps = () => {
    const filename = "Reseller Withdrawal.csv";
    const finalData =
      Object.entries(data?.data || {})?.map(([key, item]: any) => {
        let inner = {};
        Object.entries(item?.data || {})?.forEach(([_, subItem]) => {
          Object.entries(subItem || {})?.forEach(([_, subItem2]) => {
            inner = { ...subItem2 };
          });
        });
        return {
          ...item,
          date: key,
          ...inner,
        };
      }) || [];
    const headers =
      withdrawalColumns?.map((val) => ({
        label: val?.title,
        key: val?.name,
      })) || [];
    headers.unshift({
      label: "Date",
      key: "date",
    });
    return {
      filename,
      data: finalData,
      headers,
    };
  };

  const onGetData = async () => {
    const values = getValues();

    const payload: any = {
      ...filter,
      filter_data: {
        startDate: dateFormatter(values?.date?.[0] || new Date(), "start"),
        endDate: dateFormatter(values?.date?.[1] || new Date(), "end"),
        merchantId: values.client,
      },
    };
    delete payload["isFilter"];
    getWithdrawal(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setLastFilter(values);
    setFilter({ ...FILTER, isFilter: true });
  };

  const getClientOptions = () => {
    const options = data?.merchantList
      ?.slice()
      ?.sort((a: any, b: any) =>
        a?.merchant_name?.localeCompare(b?.merchant_name)
      )
      ?.map((val: any) => ({
        value: val?.merchant_id,
        label: val?.merchant_details?.merchant_name,
      }));
    return options?.length
      ? [{ value: "ALL", label: "ALL" }, ...options]
      : [{ value: "ALL", label: "ALL" }];
    // return [];
  };

  useEffect(() => {
    watch((values) => {
      setMaxDate(prepareMaxDate(values?.date?.[0], values?.date?.[1]));
    });
  }, [watch]);

  return (
    <>
      <div className="mt-[16px]">
        <form
          className="flex flex-wrap gap-2 items-start justify-end px-1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            {/* <div className="flex flex-wrap gap-2">
              <Select
                name="filter"
                label="Filter"
                options={filterOptions}
                register={register}
                onChange={(e) =>
                  setIsError({ ...isError, filter: e.target.value })
                }
              />
              <Input
                className="mr-2"
                name="search"
                placeholder="Enter Search Value"
                label="Search"
                register={register}
                onChange={(e) =>
                  setIsError({ ...isError, value: e.target.value })
                }
              />
            </div> */}
            <Select
              name="client"
              label="Client"
              options={getClientOptions()}
              register={register}
            />
          </div>
          {/* <Select
            name="status"
            label="Status"
            register={register}
            options={statusOptions}
          /> */}
          <ControlledDatePicker
            name="date"
            label="Date"
            placeholder="Select date"
            selectsRange
            maxDate={maxDate}
            control={control}
          />
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Apply
          </Button>
          <Button
            variant="Transaparent"
            className="mt-[22px]  !text-sm !h-9"
            onClick={() => {
              reset();
              setFilter({ ...FILTER });
            }}
          >
            Clear
          </Button>
          <span onClick={() => showToaster("Please select client", "Error")}>
            <Export
              {...prepareCsvProps()}
              className={`btn btn-primary rounded mt-[22px]  !text-sm !h-9 ${isExportable() ? "" : "disabled opacity-50"
                }`}
            >
              Download
            </Export>
          </span>
        </form>

        <div className="mt-2">
          <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryData?.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryData.length}
                    index={index}
                    value={data?.summary?.[item.key]}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="App mt-3 tableborder">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={Object.entries(data?.data || {})}
            >
              {Object.entries(data?.data || {})?.map(
                ([key, item]: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow columns={columns} item={{ ...item, date: key }}>
                        <Table
                          className="subtable"
                          isExpendable={false}
                          columns={withdrawalColumns}
                          data={Object.entries(data?.data || {})}
                          minHeight={50}
                        >
                          {Object.entries(item?.data || {})?.map(
                            ([subKey, subItem]: any, subIndex: number) => {
                              return (
                                <Fragment key={subIndex}>
                                  {Object.entries(subItem || {})?.map(
                                    (
                                      [subKey2, subItem2]: any,
                                      subIndex2: number
                                    ) => {
                                      return (
                                        <>
                                          <Fragment key={subIndex2}>
                                            <TableRow
                                              columns={withdrawalColumns}
                                              item={{
                                                ...subItem2,
                                                merchantId: subKey,
                                              }}
                                              isExpendable={false}
                                            ></TableRow>
                                          </Fragment>
                                        </>
                                      );
                                    }
                                  )}
                                </Fragment>
                              );
                            }
                          )}
                        </Table>
                      </TableRow>
                    </Fragment>
                  );
                }
              )}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
