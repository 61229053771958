import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { dateFormatter } from "../../helperFunctions";
import { FILTER } from "../../constant";
import { Card } from "../common";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useSettlementData, useSettlementSummaryData } from "./settlementSlice";
import {
  useGetResellerSettlementMutation,
  useGetResellerSettlementSummaryMutation,
} from "./settlementApi";
import { summaryDataSettelment } from "./mock";

const Settlement = () => {
  const data = useSettlementData();
  const summaryData = useSettlementSummaryData();

  const [filter, setFilter] = useState(FILTER);
  const [getResellerSettlement, { isLoading }] =
    useGetResellerSettlementMutation();
  const [getResellerSettlementSummary] =
    useGetResellerSettlementSummaryMutation();

  const columns = [
    {
      title: "Date",
      name: "settlement_at",
      Cell: (item: any) => {
        const dateString = item.settlement_at;
        const year = dateString?.substring(0, 4);
        const month = dateString?.substring(5, 7) - 1; // subtract 1 as months are zero-based in JavaScript
        const day = dateString?.substring(8, 10);

        const date = new Date(year, month, day);

        const formattedDate = date.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });

        return (
          <div>
            <p>
              <span className="celldetail">{formattedDate}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "Transaction Id",
      name: "transaction_id",
    },
    {
      title: "Reference Id",
      name: "reference_id",
    },
    {
      title: "Amount",
      name: "release_amt",
      Cell: (item: any) => {
        return (
          <>
          {item?.value ? <p>{item?.release_amt} {item?.sub_type} <span><sub>(at {item?.value} {" "}INR)</sub> </span></p> : "-"}
            {/* <span>{`${
              item?.value ? item?.value + "/" + item?.release_amt : "-"
            }`}</span> */}
          </>
        );
      },
    },
    {
      title: "Method",
      name: "type",
      Cell: (item: any) => {
        return (
          <>
            <span>{`${item?.type}${
              item?.sub_type ? "/" + item?.sub_type : ""
            }`}</span>
          </>
        );
      },
    },
    {
      title: "Settlement Amount (INR)",
      name: "settlement_amt",
      type: "₹ ",
      // Cell: (item: any) => {
      //   return (
      //     <>
      //       <span>{`${item?.settlement_amt ?  seperator(item?.settlement_amt) : seperator(item?.release_amt)}`}</span>
      //     </>
      //   );
      // },
    },
  ];

  const { handleSubmit, reset, getValues, control } = useForm({});

  const onGetData = async () => {
    const values = getValues();

    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          startDate: dateFormatter(values?.date?.[0] || new Date(), "start"),
          endDate: dateFormatter(values?.date?.[1] || new Date(), "end"),
          merchantId: values.client,
        },
      };
    } else {
      payload = {
        ...filter,
        filter_data: {
          startDate: null,
          endDate: null,
          merchantId: values.client,
        },
      };
    }
    delete payload["isFilter"];
    getResellerSettlement(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getResellerSettlementSummary(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    if (getValues()?.date !== undefined) {
      setFilter({ ...FILTER, isFilter: true });
    }
  };

  const summarydata = (item: any | undefined) => {
    const hl = summaryData?.data?.map((val: any, index: number) => {
      return val?.[item];
    });
    return hl;
  };

  return (
    <>
      <div className="mt-[16px]">
        <div className="mt-2">
          <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryDataSettelment?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryDataSettelment?.length}
                    index={index}
                    value={summarydata(item?.key)}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div>

          <form
            className="flex flex-wrap gap-2 items-start justify-end px-1 mt-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledDatePicker
              name="date"
              label="Date"
              placeholder="Date"
              selectsRange
              maxDate={new Date()}
              control={control}
            />
            <Button type="submit" className="mt-[22px] !h-9 !text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[22px]  !text-sm !h-9"
              onClick={() => {
                reset();
                setFilter({ ...FILTER });
              }}
            >
              Clear
            </Button>
          </form>
          <div className="App mt-3 tableborder">
            <Table
              isExpendable={false}
              columns={columns}
              isLoading={isLoading}
              data={data?.data}
              count={data?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                });
              }}
            >
              {data?.data?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settlement;
