import React, { PropsWithChildren } from "react";

// UI IMPORT
import { ButtonVariants, getButtonVariants } from "../theme";

// THIRD - PARTY IMPORT
import { CSVLink } from "react-csv";

interface ExportProps extends PropsWithChildren<{}> {
    className?: string;
    variant?: ButtonVariants;
    filename?: string;
    data: any[];
    headers: any[];
    onClick?: any;
}
const Export = (props: ExportProps) => {
    const {
        className = "",
        children,
        variant = "Primary",
        filename = "File.csv",
        ...rest
    } = props;
    const buttonClassName = getButtonVariants(variant);

    return (
        <CSVLink
            filename={filename}
            className={`${className} ${buttonClassName} appl-btn rounded-lg h-10 flex items-center justify-center`}
            {...rest}
        >
            {children}
        </CSVLink>
    );
};

export default Export;
