export const summaryData = [
  {
    imgSrc: "RechargeIntialized",
    key: "total_deposit_success_amount",
    text: "Deposit Amount",
  },
  {
    imgSrc: "RechargePending",
    key: "total_deposit_commission",
    text: "Deposit Commission",
  },
  {
    imgSrc: "RechargeProcess",
    key: "total_withdrawal_success_amount",
    text: "Withdrawal Amount",
  },
  {
    imgSrc: "DashboardWithdrawal",
    key: "total_withdrawal_commission",
    text: "Withdrawal Commission",
  },
  {
    imgSrc: "DashboardDeposite",
    key: "total_amount",
    text: "TotaL Commission",
  },
];

export const filterOptions = [
  // { value: "txnId", label: "Transaction Id" },
  { value: "merhantId", label: "Merchant Id" },
  // { value: "ordId", label: "Order Id" },
  // { value: "bankRrn", label: "Bank RRN" },
  // { value: "custEmail", label: "Customer Email" },
  // { value: "custId", label: "Customer Id" },
  // { value: "custMobile", label: "Customer Mobile" },
  // { value: "paymentAmt", label: "Amount" },
  // { value: "udf1", label: "UDF 1" },
  // { value: "udf2", label: "UDF 2" },
  // { value: "udf3", label: "UDF 3" },
  // { value: "udf4", label: "UDF 4" },
  // { value: "udf5", label: "UDF 5" },
];

export const typeOptions = [
  { value: "All", label: "All" },
  { value: "Deposit", label: "Deposit" },
  { value: "Withdrawal", label: "Withdrawal" },
];
