import { Route, Routes } from "react-router";

// PROJECT IMPORT
import ProtectedRoute from "./ProtectedRoute";
import { Login } from "../../features";
import Admin from "./Admin";
import { useUser } from "../../features/auth/authSlice";

const AllRoutes = () => {
  const user = useUser();
  return (
    <Routes>
      {!user?.token ? (
        <Route>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Route>
      ) : (
        <Route element={<ProtectedRoute />}>
          <Route path="/*" element={<Admin />} />
        </Route>
      )}
    </Routes>
  );
};

export default AllRoutes;
