import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../ui";
import { useIsDrawerOpen } from "../common/commonSlice";

const SideBarMenu = (props: any) => {
  const { name, route, icon_class, onClick } = props;
  
  const location = useLocation();
  const isDrawerOpen = useIsDrawerOpen();

  return (
    <ul className="mainMenu" key={name}>
      <li onClick={onClick}>
        <div title={name}>
          <Link
            to={{ pathname: route }}
            className={`${location.pathname === route && "activeMenu"} ${
              isDrawerOpen ? "justify-between" : "justify-center"
            }`}
          >
            <div className={`flex ${isDrawerOpen ? " px-5" : "py-2.5"}`}>
              <Icon
                name={icon_class}
                className={`${isDrawerOpen ? "me-4" : ""}  `}
              />
              {isDrawerOpen ? (
                <span className="text-capitalize text-base">
                  {name}
                </span>
              ) : null}
            </div>
            {props?.children && <i className="fa-solid fa-angle-right"></i>}
          </Link>
        </div>
      </li>
    </ul>
  );
};

export default SideBarMenu;
