export const filterOptions = [
  { value: "withdrawalId", label: "Payout Id" },
  { value: "refId", label: "Reference Id" },
  { value: "acNo", label: "Account Number" },
  { value: "ifscCode", label: "IFSC" },
  { value: "custEmail", label: "Customer Email" },
  { value: "mobileNo", label: "Customer Mobile" },
  { value: "amount", label: "Amount" },
  { value: "udf1", label: "UDF 1" },
  { value: "udf2", label: "UDF 2" },
  { value: "udf3", label: "UDF 3" },
  { value: "udf4", label: "UDF 4" },
  { value: "udf5", label: "UDF 5" },
];

export const summaryData = [
  {
    imgSrc: "DashboardDeposite",
    key: "total_payout_txn",
    text: "Total Withdrawal",
    rupee: false
  },
  {
    imgSrc: "DashboardWithdrawal",
    key: "total_payout_amount",
    text: "Withdrawal Amount",
  },
  {
    imgSrc: "DashboardUnsettled",
    key: "total_success_amount",
    text: "Total Withdrawal Amount",
  },
  {
    imgSrc: "DashboardActiveBalance",
    key: "total_payout_commission",
    text: "Total Withdrawal Commission",
  },
];
