import { useEffect, Fragment, useState } from "react";

// PROJECT IMPORT
import {
  useGetDepositChartMutation,
  useGetMerchantListMutation,
  useGetSummaryMutation,
  useGetWithdrawalChartMutation,
} from "./dashboardApi";
import {
  useDepositChart,
  useMerchantList,
  useSummaryItems,
  useWithdrawalChart,
} from "./dashboardSlice";
import { staticSummaryData } from "./mock";
import { Card } from "../common";
import {
  Button,
  ControlledDatePicker,
  DatePicker,
  Loader,
  Select,
  Table,
  TableRow,
  getStatusTypes,
} from "../../ui";
import { FILTER } from "../../constant";
import { dateFormatter, seperator } from "../../helperFunctions";
import { useForm } from "react-hook-form";
import BarChart from "../../components/Custom/Elements/Chart/BarChart";

const Dashboard = () => {
  const summaryItems = useSummaryItems();
  const merchantList = useMerchantList();
  const depositChart = useDepositChart();
  const withdrawalChart = useWithdrawalChart();
  const [getMerchantList, { isLoading: isListLoading }] =
    useGetMerchantListMutation();
  const [getDepositChart, { isLoading: isDepositChartLoading }] =
    useGetDepositChartMutation();
  const [getWithdrawalChart, { isLoading: isWithdrawalChartLoading }] =
    useGetWithdrawalChartMutation();
  const [getSummary] = useGetSummaryMutation();
  const [filter, setFilter] = useState(FILTER);

  const initialDateFilter = {
    startDate: new Date(),
    endDate: new Date(),
  };

  const [chartFilter, setChartFilter] = useState({
    ...initialDateFilter,
    merchantId: "ALL",
  });

  const { register, handleSubmit, reset, getValues, control } = useForm({
    defaultValues: {
      date: [new Date(), new Date()],
    },
  });

  const columns = [
    {
      title: "Merchant Name",
      name: "merchant_name",
    },
    {
      title: "Total Commission",
      name: "total_commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_commission)}</span>;
      },
    },
    {
      title: "Total Deposit",
      name: "total_payin",
    },
    // {
    //   title: "Payin Fees",
    //   name: "total_payin_fees",
    // },
    {
      title: "Total Payout",
      name: "total_payout",
    },
    // {
    //   title: "Payout Fees",
    //   name: "total_payout_fees",
    // },
    {
      title: "Available Balance",
      name: "availableBalance",
    },
    {
      title: "Account Status",
      name: "total_payout",
      Cell: (item: any) => {
        return (
          <span className={`${getStatusTypes(item?.account_status)}`}>
            {item?.account_status}
          </span>
        );
      },
    },
  ];

  const depositOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Deposit Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
  };

  const withdrawalOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Withdrawal Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
  };

  const onGetChart = async () => {
    const { startDate, endDate, ...rest } = chartFilter;
    const payload: any = {
      filter_data: rest,
      startDate: dateFormatter(startDate, "start"),
      endDate: dateFormatter(endDate, "end"),
    };
    getDepositChart(payload);
    getWithdrawalChart(payload);
  };

  const onGetData = async () => {
    const values: any = getValues();
    let payload: any = {};

    payload = {
      ...filter,
      startDate: dateFormatter(values?.date?.[0] || new Date(), "start"),
      endDate: dateFormatter(values?.date?.[1] || new Date(), "end"),
      filterData: {
        merchantId: values.client,
      },
    };
    delete payload["isFilter"];
    getMerchantList(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    onGetChart();
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const getClientOptions = () => {
    const options = depositChart?.merchantList
      ?.slice()
      ?.sort((a: any, b: any) =>
        a?.merchant_name?.localeCompare(b?.merchant_name)
      )
      ?.map((val: any) => ({
        value: val?.merchant_id,
        label: val?.merchant_details?.merchant_name,
      }));
    return options?.length
      ? [{ value: "ALL", label: "ALL" }, ...options]
      : [{ value: "ALL", label: "ALL" }];
  };

  return (
    <div className="space-y-2 mt-[16px]">
      <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 mainbox">
        {staticSummaryData?.map((item: any, index) => (
          <Fragment key={index}>
            <Card
              length={summaryItems?.length}
              index={index}
              value={+summaryItems?.[item.key]}
              text={item?.text}
              icon={item?.img}
            />
          </Fragment>
        ))}
      </div>
      <div>
        <div className="flex flex-md-row  justify-between  items-start	">
          <h4 className="main-title">
            <b>Deposit Chart</b>
          </h4>
          <div className="my-xl-auto right-content align-items-center">
            <div
              className="flex items-center md:mt-0 mt-1"
              style={{ flexWrap: "wrap" }}
            >
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <div className="flex items-end inputgap gap-2">
                    <Select
                      label="Client"
                      options={getClientOptions()}
                      value={chartFilter?.merchantId}
                      onChange={(selectedOption) => {
                        setChartFilter({
                          ...chartFilter,
                          merchantId: selectedOption?.target?.value,
                        });
                      }}
                    />
                    <DatePicker
                      label="Date"
                      maxDate={new Date()}
                      placeholder="Select Date"
                      selectsRange
                      startDate={chartFilter?.startDate}
                      endDate={chartFilter?.endDate}
                      onChange={(selectedDate) => {
                        setChartFilter({
                          ...chartFilter,
                          startDate: selectedDate?.[0] || null,
                          endDate: selectedDate?.[1] || null,
                        });
                      }}
                    />
                    <Button type="button" onClick={() => onGetChart()}>
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isDepositChartLoading || isWithdrawalChartLoading ? (
            <div
              style={{
                height: 600,
              }}
              className="flex-center items-center justify-center flex"
            >
              <Loader />
            </div>
          ) : (
            <div className="chartcss">
              <BarChart
                data={depositChart?.data ? depositChart?.data : []}
                options={depositOptions}
                isLoading={isDepositChartLoading}
              />
              <h4 className="main-title">
                <b>Withdrawal Chart</b>
              </h4>
              <BarChart
                data={withdrawalChart?.data ? withdrawalChart?.data : []}
                options={withdrawalOptions}
                isLoading={isWithdrawalChartLoading}
              />
            </div>
          )}
        </div>
      </div>
      <form
        className="flex flex-wrap justify-end gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          name="client"
          label="Client"
          options={getClientOptions()}
          register={register}
        />
        <ControlledDatePicker
          name="date"
          label="Date"
          placeholder="Date"
          selectsRange
          maxDate={new Date()}
          control={control}
        />
        <Button type="submit" className="mt-[22px] !h-9 !text-sm">
          Apply
        </Button>
        <Button
          variant="Transaparent"
          className="mt-[22px]  !text-sm !h-9"
          onClick={() => {
            reset();
            setFilter({ ...FILTER });
          }}
        >
          Clear
        </Button>
      </form>
      <Table
        isExpendable={false}
        columns={columns}
        isLoading={isListLoading}
        data={merchantList?.data}
      >
        {merchantList?.data?.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              <TableRow
                columns={columns}
                item={item}
                isExpendable={false}
              ></TableRow>
            </Fragment>
          );
        })}
      </Table>
    </div>
  );
};

export default Dashboard;
