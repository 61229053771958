export const summaryData = [
  {
    imgSrc: "RechargeSuccess",
    key: "total_payin_txn",
    text: "Total Trasaction",
    rupee: false,
  },
  {
    imgSrc: "RechargePending",
    key: "total_payin_amount",
    text: "Total Payin Amount",
  },
  {
    imgSrc: "RechargeIntialized",
    key: "total_success_amount",
    text: "Total Success Amount",
  },
  {
    imgSrc: "RechargeProcess",
    key: "total_payin_commission",
    text: "Total Commission",
  },
];

export const filterOptions = [
  // { value: "txnId", label: "Transaction Id" },
  { value: "merhantId", label: "Merchant Id" },
  // { value: "ordId", label: "Order Id" },
  // { value: "bankRrn", label: "Bank RRN" },
  // { value: "custEmail", label: "Customer Email" },
  // { value: "custId", label: "Customer Id" },
  // { value: "custMobile", label: "Customer Mobile" },
  // { value: "paymentAmt", label: "Amount" },
  // { value: "udf1", label: "UDF 1" },
  // { value: "udf2", label: "UDF 2" },
  // { value: "udf3", label: "UDF 3" },
  // { value: "udf4", label: "UDF 4" },
  // { value: "udf5", label: "UDF 5" },
];
